import Vue from "vue";
import store from "@/store";
import axios from "axios";

Vue.prototype.$version = "4.8.8";

/*第一层if判断生产环境和开发环境*/
if (process.env.NODE_ENV === "production") {
  /*第二层if，根据.env文件中的VUE_APP_FLAG判断是生产环境还是测试环境*/
  if (process.env.VUE_APP_FLAG === "prod") {
    //production 生产环境
    Vue.prototype.$baseUrl = "https://jz.lsamx.com/";
  } else {
    //test 测试环境
    Vue.prototype.$baseUrl = "https://jz.lsamx.com";
  }
} else {
  // dev 开发环境
  Vue.prototype.$baseUrl = "https://jz.lsamx.com/";
  // Vue.prototype.$baseUrl = "/api/"; //调本地接口
}

const instance = axios.create({
  baseURL: Vue.prototype.$baseUrl,
});

instance.interceptors.request.use(
  (config) => {
    config.headers.token = store.getters.token;
    config.headers.Version = Vue.prototype.$version;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    // 处理响应失败
    return Promise.reject(error);
  }
);

Vue.prototype.$axios = instance;

export default instance;
